import React from 'react'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import { Link } from 'gatsby'

class Index extends React.Component {
  render() {

    return (
      <Layout>
        <section id="top" >
          <Nav />
        </section>
        <header id="header" className="home" style={{ height: '100vh' }}>
          <div className="wrapper text-center" style={{ height: '100%' }}>
            <div className="header__hero">
              <h1 className="text-center header">404</h1>
              <Link to="/" className="button gold" style={{ margin: '1em 0' }}>Go home</Link>
            </div>
          </div>
        </header>
      </Layout>
    )
  }
}

export default Index
